<script>
// La evaluacion ocurre en el documento en tres valores aprobado, rechazado, pendiente

import DropFile from '@/components/helexium/DropFile.vue';

export default
    {
        components: {
            DropFile
        },
        name: 'CargarDocumentosDeSeleccion',
        data() {
            return {
                etapas: [],
                documentosExistentes: []
            }
        },
        async mounted() {
            await this.getEtapas();
            this.getDocumentos();
        },
        methods:
        {
            nextStepOnSuccess( ) {
                this.getDocumentos();
            },
            async getEtapas() {
                const response = await this.$store.getters.fetchGet({ path: `ReclutamientoYSeleccion/Seleccion/etapas/${this.$route.params.vacanteId}` });
                const etapas = await response.json();
                this.etapas = etapas;
                console.log("Estapas", etapas);
            },
            getDocumentos() {
                this.$store.getters.fetchGet({ path: `ReclutamientoYSeleccion/Seleccion/documentos/vacante/${this.$route.params.vacanteId}/postulacion/${this.$route.params.postulacionId}` })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Error en el backend o DB');
                    })
                    .then(data => {
                        this.documentosExistentes = data;
                        console.log("Documentos", data);
                    })
                    .catch(error => {
                        console.log(error);
                    })

            },
            documentosInjection(requerimiento) {
                return {
                    vacanteId: this.$route.params.vacanteId,
                    postulacionId: this.$route.params.postulacionId,
                    etapaTipoId: requerimiento.tipo.id,
                    nombreIdEtapas: requerimiento.nombre,
                }
            },
            isDocumentExist(nombre) {
                return this.documentosExistentes.filter(element => element.nombreIdEtapas == nombre).length > 0;
            },
            getByName(nombre) {
                return this.documentosExistentes
                .find(element => element.nombreIdEtapas == nombre)
            },
            isEvaluated(nombre) {
                
                if( this.getByName(nombre) ) 
                {
                    return this.getByName(nombre).estado.estado != 'Pendiente';
                }
                else 
                {
                    return false;
                }
            }
        },
        computed:
        {
            requerimientos() {
                // sort by etapa.tipo.jsString puede ser null o string
                return this.etapas.sort((a, b) => {
                    if (a.tipo.jsString == null) return 1;
                    if (b.tipo.jsString == null) return -1;
                    return a.tipo.jsString.localeCompare(b.tipo.jsString);
                });
            }

        }
    }
</script>
<template>
    <div >
        <div class="container-fluid">
            <card class="pr-3">
                <template v-slot:headerTitle>
                    <div class="d-flex">
                        <div class="mr-2">
                            <router-link :to="{ name: 'AdministradorDeVacantes' }">
                                <i class="fas fa-arrow-left fa-lg px-1 text-primary"></i>
                            </router-link>
                        </div>
                        <h4 class="card-title"><b>Requerimientos del proceso de selección</b></h4>
                    </div>
                </template>
                <div v-for="etapa in requerimientos" :key="etapa.id" class="mb-5">
                    <!-- <div class="mb-3" v-if="etapa.tipo.jsString != null"> -->
                    <div >
                        <h3 class="title">{{ etapa.nombre }}</h3>
                        <div v-if="isDocumentExist(etapa.nombre)">
                            <p class="subTitle">
                                Ya existe un documento cargado
                                <a target="_blank" :href="getByName(etapa.nombre).urlImg">Documento</a>
                            </p>
                            
                            <p>
                                El/La {{ etapa.nombre }} está
                                {{getByName(etapa.nombre).estado.estado}}

                                <span v-if="getByName(etapa.nombre).estado.estado == 'Aprobado'">
                                    <i class="fas fa-check-circle text-success"></i>
                                </span>
                                <span v-else-if="getByName(etapa.nombre).estado.estado == 'Rechazado'">
                                    <i class="fas fa-times-circle text-danger"></i>
                                </span>
                                <span v-else>
                                    <i class="fas fa-clock text-warning"></i>
                                </span>
                            </p>
                        </div>
                        <div>
                            No se ha cargado el documento o evaluado el/la {{ etapa.nombre }}
                        </div>

                        <div v-if="isEvaluated(etapa.nombre)">
                            
                        </div>
                        <div v-else>
                            <DropFile v-if="etapa.tipo.jsString != null" @onUpload="nextStepOnSuccess"
                                :urlDir="`ReclutamientoYSeleccion/DocumentosSeleccion/vacantes/${$route.params.vacanteId}/postulacion/${$route.params.postulacionId}/${etapa.nombre}`"
                                :pathGet="`ReclutamientoYSeleccion/Seleccion/documentos/vacante/${$route.params.vacanteId}/postulacion/${$route.params.postulacionId}/nombreEtapa/${etapa.nombre}`"
                                pathPost="ReclutamientoYSeleccion/Seleccion/documentos" :injectData="documentosInjection(etapa)"
                                :fileTypes="etapa.tipo.jsString.split('|')"
                                :autoIncrement="false"
                                >
                                <template #prevFooter>
                                    <p class="mt-2">{{ etapa.detalles }}</p>
                                </template>
                                <template #nameSuccess v-if="isDocumentExist(etapa.nombre)">
                                    Actualizar
                                </template>
                            </DropFile>
                        </div>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>
<style scoped>
.title {
    font-size: 1.4rem;
}
.subTitle {
    font-size: 1.2rem;

}
</style>